<template>
  <div>
    <data-table
      v-model="groups"
      :show="false"
      :show-filters="false"
      :permission="permission"
    ></data-table>
  </div>
</template>

<script>
export default {
  name: 'groups-and-permissions',
  data() {
    return {
      permission: {
        module: 'Einstellungen',
        subModule: 'Einstellungen',
        childModule: 'Allgemein Einstellung',
        operation: 'edit',
        options: ['All', 'Only own personal data'],
      },
      groups: {
        columns: [
          {
            label: 'Name',
            field: ['display_name'],
            type: 'text',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '75',
            postfix: '',
          },
          { label: 'Aktions', field: '', type: 'actions', width: '10' },
        ],
        rows: [],
        actions: {
          index: null,
          list: ['edit'],
          callback: state => {
            this.$bus.$emit('show-modal', {
              show: true,
              title: state.type === 'edit' ? 'Gruppe bearbeiten' : 'Gruppe löschen',
              component: state.type === 'delete' ? 'remove-dialog' : 'new-groups-and-permissions',
              request: state.type,
              message: `Möchten Sie wirklich den Gruppe ${state.payload.name} entfernen?`,
              resource: `/users/groups/${state.payload.id}/`,
              data: state.payload,
              maxWidth: 'w-full md:w-4/5',
              event: 'reload-groups-data-table',
              dialogType: 'edit',
              type: state.type,
            })
          },
        },
        resource: 'users/groups',
      },
      breadcrumbs: {
        title: [
          { link: '/settings', crumb: 'Allgemeine Einstellungen' },
          { crumb: 'Gruppen verwalten' },
        ],
        actions: {
          title: 'Gruppen verwalten',
          component: 'new-groups-and-permissions',
          resource: '/users/groups',
          maxWidth: 'w-4/5',
          event: 'reload-groups-data-table',
          disabled: true,
        },
        permission: {
          module: 'Einstellungen',
          subModule: 'Einstellungen',
          childModule: 'Allgemein Einstellung',
          operation: 'edit',
          options: ['All', 'Only own personal data'],
        },
        pageTitle: 'Gruppen verwalten',
      },
    }
  },
  mounted() {
    this.init()

    this.$bus.$off('reload-groups-data-table')
    this.$bus.$on('reload-groups-data-table', this.init)
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>

<style></style>
